export const exportJs = async (cart, user) => {
  const ExcelJS = require("exceljs")

  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet("panier")
  sheet.columns = [
    { header: "Produit", key: "desc", width: 40 },
    { header: "Quantité", key: "qty", width: 10 },
    { header: "Prix unitaire", key: "unitPrice", width: 15 },
    { header: "Prix de la ligne", key: "rowPrice", width: 15 },
  ]
  cart.products.forEach(e => {
    sheet.addRow([
      e.description.replace(/<[^>]*>?/gm, ""),
      e.qty,
      ((e.price / 1.2) * (1 + user.margin / 100)).toFixed(2),
      ((e.price / 1.2) * (1 + user.margin / 100) * e.qty).toFixed(2),
    ])
  })

  // write to a file

  workbook.xlsx.writeBuffer().then(function(data) {
    const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement("a")
    anchor.href = url
    anchor.download = "panier.xls"
    anchor.click()
    window.URL.revokeObjectURL(url)
  })
}
