import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

import ProductsPanier from "../components/productsPanier"
import SEO from "./../components/seo"

import Layout from "../components/layout"
import { useDispatch, useSelector } from "react-redux"
import { removeAllProductsReseller } from "../actions"
import { exportJs } from "../services/exportJs"

export const Cart = props => {
  const mincommande = 450
  const cart = useSelector(s => s.cart)
  const resellerCart = useSelector(s => s.resellerCart)
  const dispatch = useDispatch()
  const identifiants = useSelector(s => s.identifiants).identifiants

  const testMinCommande = (total, mincommande) => {
    const boolOnlySample = cart.products.every(produit => produit.type == "echantillon" || (typeof produit.echantillon !== "undefined" && produit.echantillon))
    return boolOnlySample || total >= mincommande
  }
  const total = parseFloat(cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0))
  const [productNotExistInRedux, setProductNotExistInRedux] = useState(0)

  const productNotExistInReduxUpd = exist => {
    setProductNotExistInRedux(exist)
  }

  const boolMinCommande = testMinCommande(total, mincommande)
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO title="Mon Panier" description="Mon panier" />
        <h2 style={{ marginTop: "6rem" }}>Mon panier</h2>
        <ProductsPanier
          removeProduct={() => {}}
          updateQtyProduct={() => {}}
          showQuantite={true}
          productNotExistInRedux={productNotExistInReduxUpd}
          boolMinCommande={boolMinCommande}
          min_commande={mincommande}
        />
        {identifiants !== null && identifiants.mode ? (
          <>
            <button
              onClick={() => {
                dispatch(removeAllProductsReseller())
                navigate("/cadres")
              }}
              className="button"
            >
              Nouveau client
            </button>
            <button className="button" onClick={() => exportJs(resellerCart, identifiants)}>
              Exporter
            </button>
          </>
        ) : total != 0 ? (
          productNotExistInRedux === 1 ? (
            <div className="has-text-centered notification is-warning ">
              <p>Pour pouvoir commander il faut supprimer les produits avec la mention erreur</p>
            </div>
          ) : boolMinCommande ? (
            <div className="has-text-right">
              <Link className="button is-large is-primary" to={identifiants !== null ? `/apps/commande` : `/connexion?redirect=/apps/commande`}>
                Commander
              </Link>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Layout>
    </>
  )
}

Cart.propTypes = {
  location: PropTypes.object,
}

export default Cart
